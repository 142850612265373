import {initializeApp} from "firebase/app"
import {getAuth} from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyB4jx3L7pTL_Fu7HVoj7DmSBNs3sf_035c",
    authDomain: "hamrastudies.firebaseapp.com",
    projectId: "hamrastudies",
    storageBucket: "hamrastudies.appspot.com",
    messagingSenderId: "965798161291",
    appId: "1:965798161291:web:eb7335312637e605445d8e",
    measurementId: "G-YW3R9Y7P5P",
};

const fbApp = initializeApp(firebaseConfig);
const auth = getAuth(fbApp);
export default auth;