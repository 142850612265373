import {createContext, useContext, useEffect, useState} from "react";
import {signInWithPhoneNumber, signOut, onAuthStateChanged, updateProfile} from "firebase/auth";
import auth from "../firebase/app"
import axios from "axios";

const UserContext = createContext();

export const AuthContextProvider = ({children}) => {
    const [user, setUser] = useState({});

    const loginUser = (ph, appVerifier) => {
        console.log(auth.config);
        return signInWithPhoneNumber(auth, ph, appVerifier);
    }

    const logout  = () => {
        return signOut(auth);
    }

    const updateDisplayName = async (displayName) => {
        await updateProfile(auth.currentUser, {
            displayName: displayName
        });
        console.log(auth.currentUser);
        await axios.post("https://us-central1-hamrastudies.cloudfunctions.net/hamarastudiesBackend/firebase/update", {path: `/users/`, content: {displayName: displayName}, id: auth.currentUser.uid})
        .catch(err => {
            console.log(err);
        });
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser) {
                await axios.get(`https://us-central1-hamrastudies.cloudfunctions.net/hamarastudiesBackend/firebase/readforauth/users%2F${currentUser.uid}/yes`)
                .then((response) => {
                    const userObject = {
                        ...currentUser,
                        "role": response.data.content.role
                    }
                    setUser(userObject);
                })
                .catch((error) => {
                    console.log(error);
                });
            }
            else {
                setUser(undefined);
            }
        });

        return () => {
            unsubscribe();
        }
    }, []);

    return (
        <UserContext.Provider value={{loginUser, user, logout, updateDisplayName}}>
            {children}
        </UserContext.Provider>
    );
}

export const UserAuth = () => {
    return useContext(UserContext)
}