import React, {useEffect} from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { AuthContextProvider } from "./context/authContext";
import { UserAuth } from "./context/authContext";

const Page404 = React.lazy(() => import("./404"));
const Home = React.lazy(() => import("./routes/home/Home(Board)"));
const PrgGPT = React.lazy(() => import("./routes/prgGPT/prgGPT"));
const PhoneSignIn = React.lazy(() => import("./routes/loginandsignup/login"));
const Classandotherinfo = React.lazy(() => import("./routes/home/Classandotherinfo"));
const Mcqapp = React.lazy(() => import("./routes/mcqapp/Mcqapp"));
const AdmimPanel = React.lazy(() => import("./routes/admin/adminpanel"));
const AdminView = React.lazy(() => import("./routes/admin/adminview"));
const AdminQuestionView = React.lazy(() => import("./routes/admin/adminquestionview"));
const ProtectedRoute = React.lazy(() => import("./routes/others/protectedRoute"));

function RedirectToDesiredPath() {
  const navigate = useNavigate();
  const {user} = UserAuth();
  console.log(user);
  useEffect(() => {
    if(!user) {
      navigate('/login');
    }
    else {
      navigate("/home");
    }
  }, [navigate]);

  return null; // Render nothing.
}

function RedirectToDesiredPathLogin() {
  const {user} = UserAuth();
  const navigate = useNavigate();
  console.log(user);

  useEffect(() => {
    if (user !== undefined) {
      navigate("/");
    }
  }, [navigate]);

  return <PhoneSignIn/>;
}

const Router = () => (
<BrowserRouter>
  <AuthContextProvider>
    <Routes>
      {/* Redirect from root */}
      <Route path="/" element={<RedirectToDesiredPath/>} />
      <Route path="/home" element={<ProtectedRoute><Home /></ProtectedRoute>}/>
      <Route path="/login" element={<RedirectToDesiredPathLogin/>}></Route>
      <Route path="/classandotherinfo/:information" element={<ProtectedRoute><Classandotherinfo /></ProtectedRoute>} />
      
      <Route path="/mcq">
        <Route path="game/:id/:chapter" element={<ProtectedRoute><Mcqapp /></ProtectedRoute>} />
        <Route path="admin" element={<ProtectedRoute to="admin"><AdmimPanel /></ProtectedRoute>} />
        <Route path="adminview" element={<ProtectedRoute to="admin"><AdminView /></ProtectedRoute>}></Route>
        <Route path="adminquestionview" element={<ProtectedRoute to="admin"><AdminQuestionView /></ProtectedRoute>}></Route>
      </Route>

      <Route path="/prggpt" element={<ProtectedRoute><PrgGPT/></ProtectedRoute>}/>

      {/* If no paths match, show 404 */}
      <Route path="*" element={<Page404 />} />
    </Routes>
  </AuthContextProvider>
  </BrowserRouter>
);

export default Router;