import React from 'react';
import Router from "./router";

function App() {
  return (
    <div>
      <React.Suspense>
        <Router/>
      </React.Suspense>
    </div>
  );

};


export default App;
